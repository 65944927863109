@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto+Slab:wght@300;400&display=swap');

.AppHomepage {
  background-color: #f9f8f4;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 180px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-content {
  font-size: 18px;
  color: #503d20;
}

.right-justify {
  text-align: right;
}

.App-link {
  color: #503d20;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  background-color: #f7e6ac;
}

header h1,
header h2 {
  color: #a84d31;
  text-align: center;
  text-transform: uppercase;
  position: relative;
    z-index: 5;
}

header h1 {
  font-family: 'Bebas Neue', cursive;
  font-size: 61px;
  margin-top: 1px;
}

header h2 {
  font-family: 'Roboto Slab', serif;
  font-size: 20px;
  margin-top: -17px;
  letter-spacing: 14px;
  padding-left: 14px;
}

.greenFakeButton {
  width: 290px;
  height: 43px;
  border-radius: 3px;
  background-color: #cbc78c;
  box-shadow: 2px 3px 0 #b3af77;
  font-family: 'Roboto Slab', serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 50px;
  align-self: flex-end;
  margin-bottom: 30px;
  text-decoration: none;
}

.brownHR {
  width: 75%;
  height: 2px;
  background-color: #a84d318c;
  position: absolute;
  top: 100px;

}

.brownHR::after,
.brownHR::before {
  content: "";
  width: 36px;
  height: 36px;
  background-color: #a84d31;
  border-radius: 50px;
  position: absolute;
  top: -18px;
}

.brownHR::after {
  right: -64px;
}

.brownHR::before {
  left: -64px;
}

.goldWrapper {
  background-color: #f1b161;
  width: 540px;
  border-radius: 110px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-decoration: none;
}

.goldWrapper::before {
    content: "";
    background: #f7e6ac;
    width: calc(100% + 100px);
    height: 100%;
    position: absolute;
    z-index: 1;
}

.goldWrapper::after {
  content: "";
  background: #f1b161;
  position: absolute;
  border-radius: 110px;
  border: 2px solid #f7e6ac4d;
  z-index: 1;
  top: 6px;
  right: 6px;
  bottom: 6px;
  left: 6px;
}


header a {
  font-size: 14px;
  color: darkbrown !important;
  padding: 0 15px;
}

.main-footer {
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 10px;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.main-footer span {
    letter-spacing: 12px;
}

table th {
  min-width: 130px;
}

pre {
  max-width: 100% !important;
  white-space: pre-wrap;
}

form input,
form select,
form button  {
  display: block;
  width: 100%;
}

form label {
  width: 300px;  
  display: block;
}

.contentInner {
  min-height: 610px;
  width: 800px;
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.disclaimerText {
  font-size: 11px;
}

.generalContent {
  width: 800px;
  max-width: 100%;
  padding: 0 15px;
}

.centeredText {
  text-align: center;
}

.error {
  background: pink;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px;
  margin: 10px 0;
  display: block;
  width: 100%;
}

.status {
  display: block;
  width: 100%;
  background: #d4edda;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px;
  margin: 10px 0;
}

.heroImage {
  width: 100%;
  height: 500px;
  margin: 0;
  background-image: url('./hero5.png');
  background-size: cover;
  background-position: center 80%;
  background-repeat: no-repeat;
}

.pageContent {
  width: 100%;
  margin: 0;
}

.pageContent nav {
  width: 100%;
    background: #cbc78c;
    margin: 0;
    padding: 10px;
    text-align: center;
    border-bottom: 3px solid #b3af77;
}

.pageContent nav a {
  text-decoration: none;
    text-transform: uppercase;
    color: white;
    font-family: Bebas Neue,cursive;
    padding: 0 30px;
    letter-spacing: 1px;
}

.pageContent table {
  width: 100%;
}

.pageContent th {
  width: 130px;
}

table .firstColumn {
  width: calc(100% - 260px);
}

.readittome {
  width: 100%;
  text-align: right;
}


.threeBlocks {
  width: 100%;
  padding: 15px;
  background-color: #fee3b4;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}

.threeBlocks h1,
.threeBlocks p {
  text-align: center;
  color: #735f49;
  font-family: 'Roboto Slab', serif;
}

.threeBlocks h1 {
  font-size: 24px;
  margin-bottom: 25px;
}

.threeBlocks .block1 span,
.threeBlocks .block2 span,
.threeBlocks .block3 span
{
  position: relative;
  z-index: 3;
}

.threeBlocks .block1,
.threeBlocks .block2,
.threeBlocks .block3
 {
  width: calc(33% - 50px);
  background-color: #d5d6a3;
  background-size: cover;
  background-position: center;
  height: 300px;
  color: white;
  text-shadow: 1px 1px 5px #00000061;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 56px;
  padding: 30px;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 1px 1px 8px #a4a58096;
  font-family: 'Bebas Neue', cursive;
  line-height: 1;
  text-align: right;
  position: relative;
  z-index: 0;
  -webkit-filter: saturate(0.5);
  /* filter: saturate(0.5); */
}

.threeBlocks .block1 .overlay,
.threeBlocks .block2 .overlay,
.threeBlocks .block3 .overlay {

  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #d5d6a370;
  z-index: 1;
}

.threeBlocks .block1 {
  background-image: url('./hero2.png');
  background-position: 50% 50%;
}

.threeBlocks .block2 {
  background-image: url('./hero3.png');
  background-position: 50% 50%;
}

.threeBlocks .block3 {
  background-image: url('./hero4.png');
  background-position: 50% 50%;
}

.threeBlocksInner {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-top: 30px; 
  margin-bottom: 50px;
}

.ReactModalPortal {
  position: relative;
  z-index: 10000;
}

.ReactModalPortal h5 {
  font-weight: 400;  
  font-size: 16px;
}

.adminTooltip {
  background: #f7e10d36;
  padding: 15px 10px 5px 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  margin-top: 30px;
}

.floatRight {
  float: right;
}

.playbackControls {
  bottom: 15px;
  position: fixed;
  width: 160px;
  height: 60px;
  background: #b3af77;
  border-radius: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  left: calc(50% - 80px);
}

.playbackControls button {
  appearance: none;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background: none;
  color: white;
  display: flex;
  border: 1px solid #ffffff57;
  justify-content: center;
  align-items: center;
}

.ReactModal__Content.ReactModal__Content--after-open {
  padding-bottom: 100px !important;
}

.modalButton {
  width: 350px;
  max-width: 100%;
  display: block;
  margin: 20px 0;
  height: 50px;
}

@media (max-width: 640px) {

  .greenFakeButton,
  .brownHR,
  thead {
    display: none;
  }

  table td {
    width: 100% !important;
    float: left;
  }

  table tbody {
    border-top: 3px solid black;
  }

  table tr {
    padding-bottom: 20px;
    display: inline-block;
    padding-top: 15px;
    border-bottom: 3px solid black;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    inset: 0 !important;
    padding-bottom: 100px !important;
  }
  
  .goldWrapper:before {
    display: none;
  }

  .threeBlocks .block1, .threeBlocks .block2, .threeBlocks .block3 {
    width: 100%;
    margin-bottom: 15px;
  }

  .threeBlocksInner {
    flex-direction: column;
  }

  header h1 {
    font-size: 53px;
  }

  header h2 {
    font-size: 17px;
    letter-spacing: 13px;
  }

  .heroImage {
    height: 310px;
  }

  .pageContent nav a {
    padding: 0 10px;
  }
}